.stat-pushes {
  text-align: center;
}

.stat-push {
  color: inherit;
}

.stat-push p {
  line-height: 1.2em;
  margin-top: 0px;
  opacity: .5;
}

.stat-push p:first-child {
  font-weight: 700;
  opacity: 1;
}

.start-highlight {
  position: relative;
  overflow: hidden;
}

.start-highlight-teasers {
  width: 32%;
  float: right;
}

.start-highlight-teasers > * {
  margin-top: 10px;
}

.start-highlight-teasers > *:first-child {
  margin-top: 0;
}

.start-highlight .hero {
  width: calc(68% - 10px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.start-highlight .hero img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 850px) {
  .stat-pushes {
    font-size: .8rem;
  }
}

@media only screen and (max-width: 780px) {
  .start-highlight-teasers {
    float: none;
    width: auto;
  }
  .start-highlight-teasers > * {
    width: 49%;
    float: left;
    margin-left: 2%;
    margin-top: 0;
  }
  .start-highlight-teasers > *:first-child {
    margin-left: 0;
  }
  .start-highlight .hero {
    width: auto;
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    margin-bottom: 10px;
  }
}
